import {Utils} from '@exp1/front-utils'
import {permissionApi} from 'api'
import {Params, Responses} from 'commonTypes/permissions'

export const permissionHooks = new Utils.CrudHooks<
  Params.Create,
  Params.Update,
  Responses.List,
  Responses.Item,
  Responses.Create,
  Responses.Update
>('permission', 'Permission', permissionApi)
