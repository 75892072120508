import {Components, Fields} from '@exp1/front-utils'
import {permissionHooks} from 'hooks/permissions'
import {serviceHooks} from 'hooks/services'
import {Form} from 'react-final-form'
import {useNavigate} from 'react-router-dom'
import {itemsToOptions} from 'utils/findItem'

export const Create = () => {
  const navigate = useNavigate()
  const createMutation = permissionHooks.useCreateMutation()
  const serviceListQuery = serviceHooks.useListQuery()

  const onSubmit = (values: any) => {
    createMutation.mutate(values, {
      onSuccess: (permission) => navigate(`/permissions/${permission.id}/edit`),
    })
  }

  if (!serviceListQuery.data) {
    return null
  }

  return (
    <div className="flex flex-col gap-4">
      <Components.Header.H5>Create permission</Components.Header.H5>
      <Form
        keepDirtyOnReinitialize
        onSubmit={onSubmit}
        render={({handleSubmit, values}) => {
          return (
            <form onSubmit={handleSubmit} className="flex flex-col gap-4">
              <Fields.InputField label="Name" name="name" expanded />
              <Fields.InputField label="Code" name="code" expanded />
              <Fields.SelectField
                label="Service"
                name="serviceId"
                options={itemsToOptions(serviceListQuery.data)}
                expanded
              />
              <Components.Button disabled={!values.name || !values.code || !values.serviceId} className="ml-auto">
                Create
              </Components.Button>
            </form>
          )
        }}
      />
    </div>
  )
}
