import {iAm} from 'api'
import {useQuery} from 'react-query'
import {QueryOptions} from '../types'

export const useIAmQuery = (options: QueryOptions) => {
  return useQuery(['iAm'], () => iAm(), {
    cacheTime: 0,
    ...options,
    onError: (error: any) => {
      if (error.response.status === 401) {
        window.location.href = `/sign-in?redirect=${window.location.href}`
      }
    },
  })
}
