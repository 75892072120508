import {Components} from '@exp1/front-utils'
import {locationHooks} from 'hooks/locations'

export const List = () => {
  const listQuery = locationHooks.useListQuery()

  const headers = [
    {key: 'name', value: 'Name'},
    {key: 'code', value: 'Code'},
  ]

  if (!listQuery.data) {
    return null
  }

  const rows = listQuery.data.map((location) => ({
    key: location.id,
    columns: location,
  }))

  return (
    <div className="flex flex-col gap-4">
      <Components.Header.H5>Locations</Components.Header.H5>
      <Components.Table headers={headers} rows={rows} />
    </div>
  )
}
