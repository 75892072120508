import {Components, Fields} from '@exp1/front-utils'
import {permissionHooks} from 'hooks/permissions'
import {serviceHooks} from 'hooks/services'
import {useId} from 'hooks/useId'
import {Form} from 'react-final-form'
import {useNavigate} from 'react-router-dom'
import {itemsToOptions} from 'utils/findItem'

export const Edit = () => {
  const navigate = useNavigate()
  const id = useId()
  const updateMutation = permissionHooks.useUpdateMutation()
  const removeMutation = permissionHooks.useRemoveMutation()
  const serviceListQuery = serviceHooks.useListQuery()
  const itemQuery = permissionHooks.useItemQuery(id)

  if (!itemQuery.data || !serviceListQuery.data) {
    return null
  }

  const removeEntity = () => {
    removeMutation.mutate(id, {
      onSuccess: () => navigate('/permissions'),
    })
  }

  const updateEntity = (values: any) => {
    updateMutation.mutate(values, {
      onSuccess: () => navigate('.'),
    })
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between">
        <Components.Header.H5>Edit permission</Components.Header.H5>
        <Components.Button color="red" onClick={removeEntity}>
          Remove
        </Components.Button>
      </div>

      <Form
        keepDirtyOnReinitialize
        initialValues={itemQuery.data}
        onSubmit={updateEntity}
        render={({handleSubmit, values}) => {
          return (
            <form onSubmit={handleSubmit} className="flex flex-col gap-4">
              <Fields.InputField label="Name" name="name" expanded />
              <Fields.InputField label="Code" name="code" disabled expanded />
              <Fields.SelectField
                label="Service"
                name="serviceId"
                options={itemsToOptions(serviceListQuery.data)}
                disabled
                expanded
              />
              <Components.Button disabled={!values.name || !values.code || !values.serviceId} className="ml-auto">
                Save
              </Components.Button>
            </form>
          )
        }}
      />
    </div>
  )
}
