import {Stored} from '@exp1/common-utils'
import {Basic} from 'commonTypes/roles'

type Value = Record<string, string[]>

export const valueToParam = (value: Value) => {
  const roleIds = []

  for (const serviceId of Object.keys(value)) {
    roleIds.push(...value[serviceId])
  }

  return roleIds
}

export const initValue = (roles: Stored<Basic.Entity>[]) => {
  const value: Value = {}

  for (const {serviceId} of roles) {
    if (!value[serviceId]) {
      value[serviceId] = []
    }
  }

  return value
}

export const fieldToValue = (roleIds: string[], roles: Stored<Basic.Entity>[]) => {
  const value = initValue(roles)

  for (const roleId of roleIds) {
    const role = roles.find((role) => role.id === roleId)

    if (!role) {
      throw new Error(`Can't find role by id '${roleId}'`)
    }

    const serviceId = role.serviceId

    if (value[serviceId]) {
      value[serviceId].push(roleId)
    }
  }

  return value
}
