import {Components} from '@exp1/front-utils'
import logo from 'logo.png'

export const SignOut = () => {
  return (
    <div className="w-96 mx-auto mt-24">
      <form method="POST" action="/api/sign-out">
        <img src={logo} className="h-8 mb-8 mx-auto" alt="User directory logo" />
        <Components.Text className="mb-4 text-center">Do you want to continue sign out?</Components.Text>
        <Components.Button type="submit" expanded>
          Sign out
        </Components.Button>
      </form>
    </div>
  )
}
