import {QueryClient, QueryClientProvider} from 'react-query'
import {BrowserRouter as Router} from 'react-router-dom'
import {Routing} from './Routing'
import { Providers } from '@exp1/front-utils'

const ToasterChildren = () => {
  const addToasterMessage = Providers.ToasterProvider.useToasterMessageAdder()
  const queryClientConfig = {
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
        onError(error: any) {
          addToasterMessage({
            message: error.message || 'Unknown error',
            type: 'danger',
          })
        },
      },
    },
  }

  const queryClient = new QueryClient(queryClientConfig)

  return (
    <QueryClientProvider client={queryClient}>
      <Providers.WaitingProvider>
        <Router>
          <Routing />
        </Router>
      </Providers.WaitingProvider>
    </QueryClientProvider>
  )
}

export const App = () => {
  return (
    <Providers.ToasterProvider>
      <ToasterChildren />
    </Providers.ToasterProvider>
  )
}
