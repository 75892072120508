import {Components, Fields} from '@exp1/front-utils'
import {locationHooks} from 'hooks/locations'
import {roleHooks} from 'hooks/roles'
import {serviceHooks} from 'hooks/services'
import {useId} from 'hooks/useId'
import {userHooks} from 'hooks/users'
import {filter, omit} from 'lodash'
import {Form} from 'react-final-form'
import {useNavigate} from 'react-router-dom'
import {itemsToOptions} from 'utils/findItem'
import {fieldToValue, valueToParam} from './roleUtils'

export const Edit = () => {
  const navigate = useNavigate()
  const id = useId()
  const removeMutation = userHooks.useRemoveMutation()
  const updateMutation = userHooks.useUpdateMutation()
  const itemQuery = userHooks.useItemQuery(id)
  const serviceListQuery = serviceHooks.useListQuery()
  const roleListQuery = roleHooks.useListQuery()
  const locationListQuery = locationHooks.useListQuery()

  const removeEntity = () => {
    removeMutation.mutate(id, {
      onSuccess: () => navigate('/users'),
    })
  }

  const updateEntity = (values: any) => {
    updateMutation.mutate(
      {...values, roleIds: valueToParam(values.roleIds)},
      {
        onSuccess: () => navigate('.'),
      }
    )
  }

  if (!itemQuery.data || !roleListQuery.data || !serviceListQuery.data || !locationListQuery.data) {
    return null
  }

  const initialValues = {
    ...omit(itemQuery.data, 'roleIds'),
    roleIds: fieldToValue(itemQuery.data.roleIds, roleListQuery.data),
  }

  const locationOptions = itemsToOptions(locationListQuery.data)

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between">
        <Components.Header.H5>Edit user</Components.Header.H5>
        <div className="flex gap-4">
          <Components.Button color="red" onClick={removeEntity}>
            Remove
          </Components.Button>
        </div>
      </div>
      <Form
        keepDirtyOnReinitialize
        initialValues={initialValues}
        onSubmit={updateEntity}
        render={({handleSubmit, values}) => {
          return (
            <form onSubmit={handleSubmit} className="flex flex-col gap-4">
              <Fields.InputField label="Name" name="name" disabled expanded />
              <Fields.InputField label="Email" name="email" expanded />
              <Fields.MultiSelectField
                label="Locations"
                options={locationOptions}
                name="locationIds"
                visibleNumber={locationOptions.length}
                expanded
              />
              <div className="flex flex-col gap-4">
                <Components.Header.H6>Roles</Components.Header.H6>
                <div className="flex flex-col gap-4">
                  {serviceListQuery.data.map((service) => {
                    const options = itemsToOptions(filter(roleListQuery.data, {serviceId: service.id}))

                    if (options.length === 0) {
                      return (
                        <div key={service.id}>
                          <Components.Label>{service.name}</Components.Label>
                          <Components.Text className="pl-3">No roles</Components.Text>
                        </div>
                      )
                    }

                    return (
                      <Fields.MultiSelectField
                        key={service.id}
                        label={service.name}
                        options={options}
                        name={`roleIds[${service.id}]`}
                        visibleNumber={options.length}
                        expanded
                      />
                    )
                  })}
                </div>
              </div>
              <Components.Button disabled={!values.email || values.locationIds.length === 0} className="ml-auto">
                Save
              </Components.Button>
            </form>
          )
        }}
      />
    </div>
  )
}
