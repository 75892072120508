import {Components, Providers} from '@exp1/front-utils'
import {Navbar} from 'components/Navbar'
import {Sidebar} from 'components/Sidebar'
import {useIsLoading} from 'hooks/useIsLoading'
import {SignIn} from 'pages/SignIn'
import * as Locations from 'pages/locations'
import * as Permissions from 'pages/permissions'
import * as Roles from 'pages/roles'
import * as Services from 'pages/services'
import * as Users from 'pages/users'
import {UserProvider} from 'providers/UserProvider'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {SignOut} from '../pages/SignOut'

const Dashboard = () => {
  return (
    <UserProvider>
      <Navbar />
      <div className="w-[1024px] m-auto p-4">
        <div className="flex gap-8">
          <Sidebar />
          <div className="w-full">
            <Outlet />
          </div>
        </div>
      </div>
    </UserProvider>
  )
}

export const Routing = () => {
  const isLoading = useIsLoading()
  const isWaiting = Providers.WaitingProvider.useIsWaiting()
  const showSpinner = isLoading || isWaiting

  return (
    <div>
      <Routes>
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/sign-out" element={<SignOut />} />

        <Route path="/" element={<Dashboard />}>
          <Route path="users" element={<Users.List />} />
          <Route path="users/create" element={<Users.Create />} />
          <Route path="users/:id/edit" element={<Users.Edit />} />

          <Route path="services" element={<Services.List />} />
          <Route path="services/create" element={<Services.Create />} />
          <Route path="services/:id/edit" element={<Services.Edit />} />

          <Route path="permissions" element={<Permissions.List />} />
          <Route path="permissions/create" element={<Permissions.Create />} />
          <Route path="permissions/:id/edit" element={<Permissions.Edit />} />

          <Route path="roles" element={<Roles.List />} />
          <Route path="roles/create" element={<Roles.Create />} />
          <Route path="roles/:id/edit" element={<Roles.Edit />} />

          <Route path="locations" element={<Locations.List />} />
          <Route path="/" element={<Navigate to="/users" replace={true} />} />
        </Route>
      </Routes>

      {showSpinner && <Components.Spinner />}
    </div>
  )
}
