import {Utils} from '@exp1/front-utils'
import {serviceApi} from 'api'
import {Params, Responses} from 'commonTypes/services'

export const serviceHooks = new Utils.CrudHooks<
  Params.Create,
  Params.Update,
  Responses.List,
  Responses.Item,
  Responses.Create,
  Responses.Update
>('service', 'Service', serviceApi)
