import {Utils} from '@exp1/front-utils'
import {Params, Responses} from '../commonTypes/roles'
import {axiosInstance} from './getInstance'

export const crudApi = new Utils.CrudApi<
  Params.Create,
  Params.Update,
  Responses.List,
  Responses.Item,
  Responses.Create,
  Responses.Update
>('/api/rest/roles', axiosInstance)
