import {Components} from '@exp1/front-utils'
import {serviceHooks} from 'hooks/services'
import {useNavigate} from 'react-router-dom'

export const List = () => {
  const navigate = useNavigate()
  const listQuery = serviceHooks.useListQuery()

  const createEntity = () => navigate('/services/create')

  const headers = [{key: 'name', value: 'Name'}, {key: 'code', value: 'Code'}, {key: 'edit'}]

  if (!listQuery.data) {
    return null
  }

  const rows = listQuery.data.map((service) => ({
    key: service.id,
    columns: {
      ...service,
      edit: <Components.EditLink to={`/services/${service.id}/edit`} />,
    },
  }))

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between">
        <Components.Header.H5>Services</Components.Header.H5>
        <Components.Button onClick={createEntity}>Add</Components.Button>
      </div>
      <Components.Table headers={headers} rows={rows} />
    </div>
  )
}
