import {Utils} from '@exp1/front-utils'
import {userApi} from 'api'
import {Params, Responses} from 'commonTypes/users'

export const userHooks = new Utils.CrudHooks<
  Params.Create,
  Params.Update,
  Responses.List,
  Responses.Item,
  Responses.Create,
  Responses.Update
>('user', 'User', userApi)
