import {Components, Fields} from '@exp1/front-utils'
import {permissionHooks} from 'hooks/permissions'
import {roleHooks} from 'hooks/roles'
import {serviceHooks} from 'hooks/services'
import {useId} from 'hooks/useId'
import {filter} from 'lodash'
import {Form} from 'react-final-form'
import {useNavigate} from 'react-router-dom'
import {itemsToOptions} from 'utils/findItem'

export const Edit = () => {
  const navigate = useNavigate()
  const id = useId()
  const updateMutation = roleHooks.useUpdateMutation()
  const removeMutation = roleHooks.useRemoveMutation()
  const serviceListQuery = serviceHooks.useListQuery()
  const permissionListQuery = permissionHooks.useListQuery()
  const itemQuery = roleHooks.useItemQuery(id)

  if (!itemQuery.data || !serviceListQuery.data || !permissionListQuery.data) {
    return null
  }

  const removeEntity = () => {
    removeMutation.mutate(id, {
      onSuccess: () => navigate('/roles'),
    })
  }

  const updateEntity = (values: any) => {
    updateMutation.mutate(values, {
      onSuccess: () => navigate('.'),
    })
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between">
        <Components.Header.H5>Edit role</Components.Header.H5>
        <Components.Button color="red" onClick={removeEntity}>
          Remove
        </Components.Button>
      </div>

      <Form
        keepDirtyOnReinitialize
        initialValues={itemQuery.data}
        onSubmit={updateEntity}
        render={({handleSubmit, values}) => {
          const serviceOptions = itemsToOptions(serviceListQuery.data)
          const permissionOptions = itemsToOptions(filter(permissionListQuery.data, {serviceId: values.serviceId}))
          return (
            <form onSubmit={handleSubmit} className="flex flex-col gap-4">
              <Fields.InputField label="Name" name="name" expanded />
              <Fields.InputField label="Code" name="code" disabled expanded />
              <Fields.SelectField label="Service" name="serviceId" options={serviceOptions} disabled expanded />
              <Fields.MultiSelectField
                label="Permissions"
                name="permissionIds"
                options={permissionOptions}
                visibleNumber={permissionOptions.length}
                expanded
              />
              <Components.Button disabled={!values.name || !values.code || !values.serviceId} className="ml-auto">
                Save
              </Components.Button>
            </form>
          )
        }}
      />
    </div>
  )
}
