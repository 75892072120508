import {App} from 'App'
import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { initializeApp } from "firebase/app";
import { getAuth, browserLocalPersistence } from "firebase/auth";

const start = async () => {
  const firebaseConfig = {
    apiKey: "AIzaSyBRn0r6TV1Hgtis9edu2J3OiDzWhKT7GrA",
    authDomain: "sandbox-221.firebaseapp.com",
    projectId: "sandbox-221",
    storageBucket: "sandbox-221.appspot.com",
    messagingSenderId: "690021226416",
    appId: "1:690021226416:web:56952dbd8cdb68a1c86875"
  }

  initializeApp(firebaseConfig)

  await getAuth().setPersistence(browserLocalPersistence)

  const rootElement = document.getElementById('root')

  if (!rootElement) {
    throw new Error("Can't find a root element")
  }

  const root = ReactDOM.createRoot(rootElement)

  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  )
}

start().catch(console.error)
