import {Components, Fields} from '@exp1/front-utils'
import {locationHooks} from 'hooks/locations'
import {roleHooks} from 'hooks/roles'
import {serviceHooks} from 'hooks/services'
import {userHooks} from 'hooks/users'
import {filter} from 'lodash'
import {Form} from 'react-final-form'
import {useNavigate} from 'react-router-dom'
import {itemsToOptions} from 'utils/findItem'
import {initValue, valueToParam} from './roleUtils'

export const Create = () => {
  const navigate = useNavigate()
  const createMutation = userHooks.useCreateMutation()
  const serviceListQuery = serviceHooks.useListQuery()
  const roleListQuery = roleHooks.useListQuery()
  const locationListQuery = locationHooks.useListQuery()

  const onSubmit = (values: any) => {
    createMutation.mutate(
      {...values, roleIds: valueToParam(values.roleIds)},
      {
        onSuccess: (user) => navigate(`/users/${user.id}/edit`),
      }
    )
  }

  if (!serviceListQuery.data || !roleListQuery.data || !locationListQuery.data) {
    return null
  }

  const initialValues = {
    roleIds: initValue(roleListQuery.data),
    locationIds: [],
  }

  const locationOptions = itemsToOptions(locationListQuery.data)

  return (
    <div className="flex flex-col gap-4">
      <Components.Header.H5>Create user</Components.Header.H5>
      <Form
        initialValues={initialValues}
        keepDirtyOnReinitialize
        onSubmit={onSubmit}
        render={({handleSubmit, values}) => {
          return (
            <form onSubmit={handleSubmit} className="flex flex-col gap-4">
              <Fields.InputField label="Email" name="email" type="email" spellCheck={false} expanded />
              <Fields.MultiSelectField
                label="Locations"
                options={locationOptions}
                name="locationIds"
                visibleNumber={locationOptions.length}
                expanded
              />
              <div className="flex flex-col gap-4">
                <Components.Header.H6>Roles</Components.Header.H6>
                <div className="flex flex-col gap-4">
                  {serviceListQuery.data.map((service) => {
                    const options = itemsToOptions(filter(roleListQuery.data, {serviceId: service.id}))

                    if (options.length === 0) {
                      return (
                        <div key={service.id}>
                          <Components.Label>{service.name}</Components.Label>
                          <Components.Text className="pl-3">No roles</Components.Text>
                        </div>
                      )
                    }

                    return (
                      <Fields.MultiSelectField
                        key={service.id}
                        label={service.name}
                        options={options}
                        name={`roleIds[${service.id}]`}
                        visibleNumber={options.length}
                        expanded
                      />
                    )
                  })}
                </div>
              </div>
              <Components.Button disabled={!values.email || values.locationIds.length === 0} className="ml-auto">
                Create
              </Components.Button>
            </form>
          )
        }}
      />
    </div>
  )
}
