import {Components} from '@exp1/front-utils'
import {userHooks} from 'hooks/users'
import moment from 'moment'
import {useNavigate} from 'react-router-dom'

export const List = () => {
  const navigate = useNavigate()
  const listQuery = userHooks.useListQuery({})

  const createEntity = () => navigate('/users/create')

  const headers = [
    {key: 'name', value: 'Name'},
    {key: 'email', value: 'Email'},
    {key: 'lastSignedInAt', value: 'Last sign in at'},
    {key: 'edit'},
  ]

  if (!listQuery.data) {
    return null
  }

  const rows = listQuery.data.map((user) => ({
    key: user.email,
    columns: {
      ...user,
      lastSignedInAt: user.lastSignedInAt ? moment(user.lastSignedInAt).format('YYYY MMM DD, HH:mm') : '-',
      edit: <Components.EditLink to={`/users/${user.id}/edit`} />,
    },
  }))

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between">
        <Components.Header.H5>Users</Components.Header.H5>
        <Components.Button onClick={createEntity}>Add</Components.Button>
      </div>
      <Components.Table headers={headers} rows={rows} />
    </div>
  )
}
