import {Components} from '@exp1/front-utils'
import {permissionHooks} from 'hooks/permissions'
import {serviceHooks} from 'hooks/services'
import {useNavigate} from 'react-router-dom'
import {getItemName} from 'utils/findItem'

export const List = () => {
  const navigate = useNavigate()
  const permissionListQuery = permissionHooks.useListQuery()
  const serviceListQuery = serviceHooks.useListQuery()

  const createEntity = () => navigate('/permissions/create')

  const headers = [
    {key: 'name', value: 'Name'},
    {key: 'code', value: 'Code'},
    {key: 'serviceName', value: 'Service'},
    {key: 'edit'},
  ]

  if (!permissionListQuery.data || !serviceListQuery.data) {
    return null
  }

  const rows = permissionListQuery.data.map((permission) => ({
    key: permission.id,
    columns: {
      ...permission,
      serviceName: getItemName(serviceListQuery.data, permission.serviceId),
      edit: <Components.EditLink to={`/permissions/${permission.id}/edit`} />,
    },
  }))

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between">
        <Components.Header.H5>Permissions</Components.Header.H5>
        <Components.Button onClick={createEntity}>Add</Components.Button>
      </div>
      <Components.Table headers={headers} rows={rows} />
    </div>
  )
}
