import {Components} from '@exp1/front-utils'
import {GoogleAuthProvider, getAuth, signInWithPopup} from 'firebase/auth'
import logo from 'logo.png'

export const SignIn = () => {
  const currentSearchParams = new URLSearchParams(window.location.search)
  console.log({currentSearchParams}, window.location.search)

  const redirect = currentSearchParams.get('redirect') || '/'

  const signIn = async () => {
    try {
      const auth = getAuth()
      const provider = new GoogleAuthProvider()
      const {user} = await signInWithPopup(auth, provider)
      const idToken = await user.getIdToken()
      const newUrl = new URL('/api/sign-in', window.location.href).toString()
      const newSearchParams = new URLSearchParams({idToken, redirect}).toString()
      window.location.href = newUrl + '?' + newSearchParams
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className="w-96 mx-auto mt-24">
      <img src={logo} className="h-8 mb-8 mx-auto" alt="User directory logo" />
      <Components.Button expanded onClick={signIn}>
        Sign in with Google
      </Components.Button>
    </div>
  )
}
