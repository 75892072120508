import {Components, Fields} from '@exp1/front-utils'
import {serviceHooks} from 'hooks/services'
import {Form} from 'react-final-form'
import {useNavigate} from 'react-router-dom'

export const Create = () => {
  const navigate = useNavigate()
  const createMutation = serviceHooks.useCreateMutation()
  const onSubmit = (values: any) => {
    createMutation.mutate(values, {
      onSuccess: (service) => navigate(`/services/${service.id}/edit`),
    })
  }

  return (
    <div className="flex flex-col gap-4">
      <Components.Header.H5>Create service</Components.Header.H5>
      <Form
        keepDirtyOnReinitialize
        onSubmit={onSubmit}
        render={({handleSubmit, values}) => {
          return (
            <form onSubmit={handleSubmit} className="flex flex-col gap-4">
              <Fields.InputField label="Name" name="name" expanded />
              <Fields.InputField label="Code" name="code" expanded />
              <Components.Button disabled={!values.name || !values.code} className="ml-auto">
                Create
              </Components.Button>
            </form>
          )
        }}
      />
    </div>
  )
}
