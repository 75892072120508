import {Utils} from '@exp1/front-utils'
import {locationApi} from 'api'
import {Responses} from 'commonTypes/locations'

export const locationHooks = new Utils.CrudHooks<
  unknown,
  {id: string}, // FIXME
  Responses.List,
  unknown,
  unknown,
  unknown
>('location', 'Location', locationApi)
