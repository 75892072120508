type Item = {
  id: string
  name: string
}

export const findItem = <T extends Item>(items: T[], id: string) => {
  return items.find((item) => item.id === id)
}

export const getItemName = <T extends Item>(items: T[], id: string) => {
  const item = findItem(items, id)
  return item ? item.name : 'UNKNOWN'
}

export const itemsToOptions = <T extends Item>(items: T[]) => {
  return items.map((item) => {
    return {
      value: item.id,
      label: item.name,
    }
  })
}
