import {Utils} from '@exp1/front-utils'
import {roleApi} from 'api'
import {Params, Responses} from 'commonTypes/roles'

export const roleHooks = new Utils.CrudHooks<
  Params.Create,
  Params.Update,
  Responses.List,
  Responses.Item,
  Responses.Create,
  Responses.Update
>('role', 'Role', roleApi)
