import {Components} from '@exp1/front-utils'
import logo from 'logo.png'
import {UserProvider} from 'providers/UserProvider'
import {Link} from 'react-router-dom'

export const Navbar = () => {
  const user = UserProvider.useUser()
  return (
    <nav className="border-gray-200 bg-gray-50">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <Link to="/" className="flex items-center">
          <img src={logo} className="h-8 mr-3" alt="User directory logo" />
          <span className="self-center text-2xl font-semibold whitespace-nowrap">User Directory</span>
        </Link>
        <div className="flex gap-4 items-center">
          <Components.StandardLink to={`/users/${user.id}/edit`}>
            {user.name || '[No user name]'}
          </Components.StandardLink>
          <Components.StandardLink to="/sign-out">Sign out</Components.StandardLink>
        </div>
      </div>
    </nav>
  )
}
